import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/the-platform/",
    label: "THE PLATFORM",
  },
]

const EvolvingThreats = () => (
  <Layout>
    <Seo title="THE PLATFORM" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>

    <div className="grid--bg --for-it-ops-4">
      <section className="section-title-top--platform ">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h1>
                <span>CyberDNA®</span> COMMAND <br />
                AND CONTROL CENTER
              </h1>
              <h4>
                SECURITY EXPERIENCED.
                <br />
                REAL-TIME COLLABORATION.
              </h4>
              <p className="content">
                A Total Security Operations Experience: Share the intel and
                weapons of cyber-war with Vigilant’s team of Sentinels, Analysts
                and Hunters
              </p>
              <Link to="/contact/" className="nav-section--cta-btn">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    get started
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
            <div className="section-col-2">
              <div className="img-home--wrap">
                <StaticImage
                  src="../images/platform/tabUpdatedPortal.png"
                  formats={["png"]}
                  alt="Vigilant Tablet"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div className="grid--bg --for-it-ops-3 ">
      <section className="section-col-2-img--platform ">
        <div className="container">
          <div className="row">
            <div className="section-col-3">
              <h4>
                Advanced analysis means you’re alerted to more of the right data
                and none of the wrong data.
              </h4>
              <Link to="/contact/" className="nav-section--cta-btn">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    schedule a demo
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
            <div className="section-col-2 pt-5 grid--block">
              <p>
                The CyberDNA® Command and Control Center never sleeps. It is
                available to you 24/7/365 and the Vigilant team is always on the
                watch. Vigilant is constantly collecting, analyzing and
                presenting the largest collated data lake (individual,
                industry-wide and international trends), detecting threats in
                their infancy and vetting those threats with our adaptive
                intelligence process. Includes automated, artificial and human
                interpretative skills.
              </p>
              <p>
                Vigilant’s visibility sensors spread throughout your network to
                unify your security across on-prem, cloud and remote
                environments. Through the CyberDNA® Command and Control Center,
                clients can witness, in real-time, the matrix of accumulated
                intelligence across dozens of control center screens, as full
                participants.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section-overview">
        <div className="container">
          <br />
          <div className="row pb-5">
            <div className="col-sm-7">
              <div className="section-col-1">
                <h4>APPROACH</h4>
                <hr />
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-6">
                    <ul className="normal-list">
                      <li>Continuously Monitor</li>
                      <li>Manage Sensitive Workflow</li>
                      <li>Access Granular Insights</li>
                      <li>Unlimited Breach Response</li>
                      <li>Full-Packet Capture</li>
                      <li>Advanced Threat Detection</li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="normal-list">
                      <li>Vulnerability Assessment Reports</li>
                      <li>Audit Logins </li>
                      <li>Access to the Largest Data Lake</li>
                      <li>Supervise BYODs</li>
                      <li>Assess Vulnerability Posture</li>
                      <li>Advanced Threat Detection</li>
                      <li>Two-Way Ticketing</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-5">
              <div className="section-col-1">
                <h4>OUTCOMES</h4>
                <hr />
              </div>

              <ul className="normal-list">
                <li>Collaborative Security</li>
                <li>Improved Decision Making</li>
                <li>Achieve and Maintain Compliance</li>
                <li>Faster Vulnerability Assessment</li>
                <li>Eliminate Production Friction</li>
                <li>Gain Visibility into Containers</li>
                <li>Discover Abandoned Accounts</li>
                <li>Accelerate Investigations</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>

    <section className="section-promo">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h3 className="cyberDNA--title">CyberDNA® COMMAND AND CONTROL CENTER <span className="cyberDNA-logo">
            <StaticImage
                  src="../images/platform/cyberdnalogo_symbol.svg"
                  formats={["png"]}
                  alt="CyberDNA® COMMAND AND CONTROL CENTER"
                />
            </span></h3>
            
            <p>
              An environment designed to optimize Vigilant’s organizational and
              technical attributes and processes. <br />
              The Center employs human, AI and information resources to
              accomplish our mission of detecting, vetting and preventing
              threats.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3">
            <h4>COMMAND CENTER</h4>
            <div className="cubby">
              <h6>Participate in Command Processes</h6>
              <p>
                Vigilant’s unique collaborative command service is achieved
                through the CyberDNA® experience. Detect together, protect
                together.
              </p>
            </div>
            <div className="cubby">
              <h6>Observe Unusual Activity and Act</h6>
              <p>
                From bandwidth spikes to remote login attempts, you will observe
                real-time discrepancies and collaborative solutions.
              </p>
            </div>
            <div className="cubby">
              <h6>Customize Detections</h6>
              <p>
                Every industry is unique and every company within them is
                unique. The CyberDNA® Command Center guides and provides
                tailored sensoring to specific needs.
              </p>
            </div>
            <div className="cubby">
              <h6>“Red Phone” Access</h6>
              <p>
                CyberDNA® provides tailored two-way ticketing and direct access
                to our hunt team provides “red phone” access to Vigilant’s
                full-team service and deployment.
              </p>
            </div>
            <div className="cubby">
              <h6>Spending Time on The Right Stuff</h6>
              <p>
                The CyberDNA® Command Center provides maximum, constant
                visibility as it simultaneously protects each client’s time and
                focus by vetting and eliminating false-positive alerts – setting
                our sights on the right priorities.
              </p>
            </div>
            <div className="cubby">
              <h6>Evolving into Super-CyberDNA</h6>
              <p>
                Collaborative command means that CyberDNA® is constantly
                iterating based upon clients’ expressed needs. So you can expect
                constantly updated features.{" "}
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <StaticImage
              src="../images/platform/tabUpdatedPlatform.png"
              formats={["png"]}
              alt="Vigilant Tablet"
            />
          </div>
          <div className="col-sm-3">
            <h4>CONTROL CENTER</h4>
            <div className="cubby">
              <h6>Fully Capture Your Data</h6>
              <p>
                Beyond log files that can be corrupted in process, CyberDNA®
                reports raw data as well as data analysis to represent the
                lifecycle of threat activity.
              </p>
            </div>
            <div className="cubby">
              <h6>Global Oversight</h6>
              <p>
                With a click your team will observe global activity on and
                against your system and not junk-data. Vigilant is constantly
                reporting based on contemporary trends.
              </p>
            </div>
            <div className="cubby">
              <h6>Bandwidth Management</h6>
              <p>
                From system deficiencies to streaming media overuse, CyberDNA®
                provides the what, when, where and how that inform the necessary
                remedy.
              </p>
            </div>
            <div className="cubby">
              <h6>Observe Data Transmission</h6>
              <p>
                From attachments, to financial files and client data around or
                beyond your environment, CyberDNA® provides visibility and
                containment.
              </p>
            </div>
            <div className="cubby">
              <h6>Preventative Security</h6>
              <p>
                In addition to the constant visibility, CyberDNA® also regularly
                reports on patterns and trends to provide and ensure
                preventative health throughout the entire system.{" "}
              </p>
            </div>
            <div className="cubby">
              <h6>Maximize Firewalls</h6>
              <p>
                Atomic indicators reveal when firewall changes need to be made,
                from IP restriction to Geo-Blocking.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section-basic-h-sub">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h3>HOW IT WORKS</h3>
            <h6>Securing On-Prem, Cloud and Remote Environments</h6>
            <hr />
          </div>
          <div className="section-col-2">
            <StaticImage
              src="../images/bg/infographic-home.svg"
              formats={["png"]}
            />
          </div>
        </div>
      </div>
    </section>
    <section className="section-logo-6">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <div className="side-binder">
              <h6>
                Security Coverage Across Cloud Providers and Source integrations
              </h6>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="section-col-2">
            <ul className="section--logo-list--platform ">
              <li>
                <StaticImage
                  src="../images/placeholder/icon-aws.png"
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt="Logo Holder"
                  className="img-fluid"
                />
              </li>
              <li>
                <StaticImage
                  src="../images/placeholder/icon-win.png"
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt="Logo Holder"
                  className="img-fluid"
                />
              </li>
              <li>
                <StaticImage
                  src="../images/placeholder/qualys.png"
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt="CrowdStrike"
                  className="img-fluid"
                />
              </li>
              <li>
                <StaticImage
                  src="../images/placeholder/hawk.png"
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt="CrowdStrike"
                  className="img-fluid"
                />
              </li>
              <li>
                <Link to="/for-it-ops/" className="nav-section--cta-btn">
                  <span className="nav-section--cta-title">Learn More</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default EvolvingThreats
